import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import AuthConsumer from '../contexts/auth';


export default function Login() {
  let { login } = AuthConsumer();

  const signin = () => {
    login();
  };

  return (
  
    <Box
      
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
    >
      <Button variant="contained" data-testid="signin-button" onClick={() => signin()}>
        <Typography variant="body1">
          Sign In with your Traxleho Account!
        </Typography>
      </Button>
    </Box>
  )
}