import { enIN } from 'date-fns/locale';
import { toDate, formatInTimeZone } from 'date-fns-tz'

export function getDate() {
  return (new Date());
}

export function extractDate(date) {
  const dateObj = new Date(date);
  let month = '' + (dateObj.getMonth() + 1);
  let day = '' + dateObj.getDate();
  let year = dateObj.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

/*
* Timestamp in ISO 8601 with timezone offset
*
*/
export function toIsoString(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? "0" : "") + norm;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
}

// 2021-11-01T08:30:22+05:30 -> 2021-11-01 08:30 AM IST
export function toISTFormatString(dateStr) {
  const parsedDate = toDate(dateStr)
  return formatInTimeZone(parsedDate, 'Asia/Calcutta', 'E, dd MMM yyyy hh:mm a', { locale: enIN })
}

// 2021-11-01 -> 2021-11-01 08:30 AM IST
export function toDateString(dateStr) {
  const parsedDate = toDate(dateStr)
  return formatInTimeZone(parsedDate, 'Asia/Calcutta', 'E, dd MMM yyyy', { locale: enIN })
}