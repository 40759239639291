import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DateRangePicker from "@mui/lab/DateRangePicker";
import PropTypes from "prop-types";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import CollapsibleTable from "./CollapsibleTable";
import { useNavigate } from "react-router-dom";
import { ActivityConsumer } from "../contexts/activity";
import { css } from "@emotion/react";
import CircleLoader from "react-spinners/CircleLoader";

const override = css`
  position:fixed;
  left: 50%;
  margin-left: -4em;
  top: 50%;
  border: 2px;
`;

export default function ActivityPage() {
  const { getActivities, dateRange, setDateRange } = ActivityConsumer();
  const [loading, setLoading] = React.useState(false);
  const isMounted = React.useRef(null);

  React.useEffect(() => {
    setLoading(true)
    isMounted.current = true
    getActivities()
    .finally(() => {
      if (isMounted.current) {
        setLoading(false)
      }
    });
    
    return () => {
      // executed when unmount
      isMounted.current = false;
    };
    // eslint-disable-next-line 
  }, [dateRange])

  function Item(props) {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
          p: 1,
          textAlign: "center",
          fontSize: "1rem",
          fontWeight: "700",
          ...sx,
        }}
        {...other}
      />
    );
  }

  Item.propTypes = {
    sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  };

  //navigate to the activity form
  const navigate = useNavigate();

  const showActivityForm = () => {
    navigate("/activity-form");
  };

  return (
    <>
      <CircleLoader color={"#4A90E2"} loading={loading} css={override} size={100} margin={5} />

      <Paper elevation={3} sx={{ top: "10px", width: "100%" }}>
        <Box
          sx={{
            m: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}

        >
          <Item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                data-testid="Date-range-picker"
                autoOk={false}
                value={dateRange}
                onChange={() => {
                }}
                onAccept={(newDateRangesValue) => {
                  console.log(newDateRangesValue)
                  setDateRange(newDateRangesValue);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} sx={{ width: "40%" }} data-testid="start"/>
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} sx={{ width: "40%" }}  data-testid="end"/>
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Item>
          <Item sx={{ width: "100px", m: "2px" }}>
            <AddCircleIcon
              color="primary"
              sx={{ fontSize: "40px" }}
              onClick={showActivityForm}
            />
          </Item>

        </Box>
        <CollapsibleTable
          sx={{
            m: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        />
      </Paper>


    </>
  );
}
