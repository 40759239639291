import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ActivityConsumer } from "../contexts/activity";
import { toISTFormatString } from "../utils/date";
import tagline from "../utils/tagline";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        // width: "30%",
        // textAlign: "left",
        // m: 0.4,
        // fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const tableHead = [
  {
    id: 1,
    name: "Activities",
    // },
    // {
    //   id: 2,
    //   name: "Date",
    // },
    // {
    //   id: 3,
    //   name: "Location",
  },
];

export function CollapsibleTableRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { border: "none" } }} key={row.activity_id}>
        <TableCell>
          {(row.comments != "" || row.image_presigned_url != "") &&
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>
        <TableCell component="th" scope="row">
          {row.thumbnail_presigned_url != "" &&
            <img
              src={row.thumbnail_presigned_url}
              style={{ width: "100px", height: "100px", border: "none" }}
              onError={(event) => event.target.style.display = 'none'}
            />
          }
        </TableCell>
        <TableCell align="left">
          <Typography variant="body1" gutterBottom component="div" style={{fontWeight: "bold"}}>
            {row.activity_type}
          </Typography>
          <Typography variant="caption" gutterBottom component="div" style={{fontStyle: "italic"}}>
            {tagline[row.activity_type]}
          </Typography>
          <Typography variant="body1" gutterBottom component="div" color={"#4A90E2"} style={{fontWeight: "bold"}}>
            {row.location_name}
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            {toISTFormatString(row.date_time)}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ p: 0, m: 0, border: "none" }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Card>
              <CardContent
                sx={{
                  display: "inline-flex",
                  flexDirection: "column",
                }}
              >

                {row.comments != "" &&
                  <Typography variant="body1" gutterBottom>
                    Comments: {row.comments}
                  </Typography>
                }
                <Item>
                  {row.image_presigned_url != "" &&
                    <img
                      src={row.image_presigned_url}
                      style={{
                        borderRadius: "10px",
                        width: "100%"
                      }}
                      onError={(event) => event.target.style.display = 'none'}
                    />
                  }
                </Item>

              </CardContent>
            </Card>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable() {
  const activityContext = ActivityConsumer();//useContext(ActivityContext);
  const activityList = activityContext.activityList;

  return (
    <TableContainer component={Paper}>
      <div style={{ margin: "10px" }} id="activity-no"> {(activityList && activityList.length == 1) && <>{activityList.length} activity</>}
        {(activityList && activityList.length > 1) && <>{activityList.length} activities</>}
        {(activityList && activityList.length == 0) && <>No activity</>} found!</div>
      {(activityList && activityList.length > 0) &&
        <Table aria-label="collapsible table" size="small"  data-testid="collapsible-table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "5%" }} />
              {tableHead.map((item) => (
                <TableCell colSpan={3} align="left" key={item.id} style={{ fontWeight: "bold", fontSize: 20, width: "90%" }}>
                  {item.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {activityList.map((row) => (
              <CollapsibleTableRow key={row.activity_id} row={row}/>
            ))}
          </TableBody>
        </Table>
      }
    </TableContainer>

  );
}
