import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { getDate, extractDate } from "../utils/date";
import AuthConsumer from "./auth";


const planReportsContext = React.createContext({
  loading: false,
  planReports: [],
  reportDeliveryDate: getDate(),
  reportLocations: [],
  reportPlanTypes: [],
  getReports: () => { },
});

function usePlanReports() {
  const localReportDeliveryDate = JSON.parse(localStorage.getItem("reportDeliveryDate")) ||
    getDate();
  const [reportDeliveryDate, setReportDeliveryDate] = useLocalStorage("reportDeliveryDate", localReportDeliveryDate);

  const localPlanReports = JSON.parse(localStorage.getItem("planReports")) || [];
  const [planReports, setPlanReports] = useLocalStorage("planReports", localPlanReports);

  const localReportLocations = JSON.parse(localStorage.getItem("reportLocations")) || [];
  const [reportLocations, setReportLocations] = useLocalStorage("reportLocations", localReportLocations);

  const localReportPlanTypes = JSON.parse(localStorage.getItem("reportPlanTypes")) || [];
  const [reportPlanTypes, setReportPlanTypes] = useLocalStorage("reportPlanTypes", localReportPlanTypes);

  const { getToken } = AuthConsumer();

  // getReports 
  const getReports = () => {
    console.log("calling getReports")
    return getToken()
      .then(authToken => {
        console.log(reportDeliveryDate);
        const deliveryDate = extractDate(reportDeliveryDate);
        let url = process.env.REACT_APP_PLAN_REPORTS_URL + "?delivery_date=" + deliveryDate
        if (reportPlanTypes.length > 0) {
          url += "&plan_types=" + reportPlanTypes.join(",")
        }
        if (reportLocations.length > 0) {
          url += "&locations=" + reportLocations.join(",")
        }

        return fetch(url, {
          method: "GET",
          mode: "cors",
          headers: {
            "Authorization": "Bearer " + authToken
          }
        })
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data["error"]) {
          setPlanReports([]);
        } else {
          for (let j = 0; j < data.length; j++) {
            const plan = JSON.parse(data[j].plan)
            for (let i = 0; i < plan.length; i++) {
              if (plan[i]["packets"]) {
                const description = []
                const quantity = []
                for (const [key, value] of Object.entries(plan[i]["packets"])) {
                  if (value["description"] !== null && value["description"] !== undefined) {
                    description.push(value["description"])
                  }
                  console.log(value)
                  if (value["quantity"] !== null && value["quantity"] !== undefined) {
                    quantity.push(value["quantity"])
                  }
                }
                if (description.length > 0) {
                  plan[i]["description"] = description
                }
                if (quantity.length > 0) {
                  plan[i]["quantity"] = quantity
                }
              }
            }
            data[j].plan = plan
          }
          setPlanReports(data);
        }
      });
  };

  return {
    reportDeliveryDate,
    setReportDeliveryDate,
    reportPlanTypes,
    setReportPlanTypes,
    reportLocations,
    setReportLocations,
    planReports,
    getReports,
  }
}

export function PlanReportsProvider({ children }) {
  const planReports = usePlanReports();

  return (
    <planReportsContext.Provider value={planReports}>
      {children}
    </planReportsContext.Provider>
  )
}

export function PlanReportsConsumer() {
  return React.useContext(planReportsContext);
}
