import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import { css } from "@emotion/react";
import CircleLoader from "react-spinners/CircleLoader";
import { PlanReportsConsumer } from "../contexts/plan-reports";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';


const override = css`
  position:fixed;
  left: 50%;
  margin-left: -4em;
  top: 50%;
  border: 2px;
`;

//Item of the order list
function Item2(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        borderRadius: 1,
        textAlign: "left",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item2.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

//item of the trace order page
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        borderRadius: 1,
        m: 1,
        p: 1,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PlanReports() {
  const { reportDeliveryDate,
    setReportDeliveryDate,
    reportPlanTypes,
    setReportPlanTypes,
    reportLocations,
    setReportLocations,
    planReports,
    getReports } = PlanReportsConsumer();
  const [loading, setLoading] = React.useState(false);
  const isMounted = React.useRef(null);

  const locations = [
    {
      "id": "VFH",
      "name": "VFH"
    },
    {
      "id": "CLT",
      "name": "CLT"
    },
    {
      "id": "BLR",
      "name": "BLR"
    }
  ]
  const plan_types = [
    {
      "id": "sourcing_plan",
      "name": "Sourcing Plan"
    },
    {
      "id": "processing_plan",
      "name": "Processing Plan"
    },
    {
      "id": "fulfilment_plan",
      "name": "Fulfillment Plan"
    },
    {
      "id": "hub_plan",
      "name": "Hub Plan"
    },
    {
      "id": "storefront_plan",
      "name": "Storefront Orders"
    },
  ]
  const headers = {
    "sourcing_plan": {
      "name": "Sourcing Plan",
      "width": "40%",
      "headers": [
        { "key": "description", "name": "Description", "align": "left" },
        { "key": "weight", "name": "Weight in kg", "align": "right" },
      ]
    },
    "processing_plan": {
      "name": "Processing Plan",
      "width": "50%",
      "headers": [
        { "key": "description", "name": "Description", "align": "left" },
        { "key": "weight", "name": "Weight in kg", "align": "right" },
        { "key": "quantity", "name": "Quantity", "align": "center" },
      ]
    },
    "fulfilment_plan": {
      "name": "Fulfillment Plan",
      "width": "99%",
      "headers": [
        { "key": "customer_id", "name": "Customer ID", "align": "left" },
        { "key": "order_id", "name": "Order ID", "align": "left" },
        { "key": "description", "name": "Packet Name", "align": "left" },
        { "key": "quantity", "name": "Packet Quantity", "align": "center" },
        { "key": "shipping_address", "name": "Shipping Address", "align": "left" },
      ]
    },
    "fulfillment_plan": {
      "name": "Fulfillment Plan",
      "width": "99%",
      "headers": [
        { "key": "customer_id", "name": "Customer ID", "align": "left" },
        { "key": "order_id", "name": "Order ID", "align": "left" },
        { "key": "description", "name": "Packet Name", "align": "left" },
        { "key": "quantity", "name": "Packet Quantity", "align": "center" },
        { "key": "shipping_address", "name": "Shipping Address", "align": "left" },
      ]
    },
    "hub_plan": {
      "name": "Hub Plan",
      "width": "99%",
      "headers": [
        { "key": "customer_id", "name": "Customer ID", "align": "left" },
        { "key": "location", "name": "Location", "align": "center" },
        { "key": "order_id", "name": "Order ID", "align": "left" },
        { "key": "quantity", "name": "Order Quantity", "align": "center" },
        { "key": "description", "name": "Packet Name", "align": "left" },
        { "key": "shipping_address", "name": "Shipping Address", "align": "left" },
      ]
    },
    "storefront_plan": {
      "name": "Storefront Orders",
      "width": "99%",
      "headers": [
        { "key": "order_id", "name": "Order ID", "align": "left" },
        { "key": "sf_sku", "name": "Storefront SKU", "align": "left" },
        { "key": "sku_description", "name": "SF Order Name", "align": "left" },
        { "key": "sf_quantity", "name": "Quantity", "align": "center" },
        { "key": "shipping_address", "name": "Shipping Address", "align": "left" },
      ]
    }
  }

  // fetch report button
  const fetchReports = () => {
    setLoading(true);
    getReports()
      .then(async () => {
        setLoading(false);
      })
      .catch(error => {
        error.json().then(err => {
          console.log(err)
          setLoading(false);
        })
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      });
  };

  // plan type selection
  const handlePlanTypeSelectionChange = (event) => {
    const {
      target: { value },
    } = event;
    setReportPlanTypes(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // location selection
  const handleLocationSelectionChange = (event) => {
    const {
      target: { value },
    } = event;
    setReportLocations(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  function PlanReportCard(props) {
    const { planReport, header, width, planName } = props;

    return (
      <Card variant="outlined" sx={{ mb: 5, borderWidth: 2, width: width }} key={planReport.plan_type + planReport.location}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            bgcolor: "lightgray"
          }}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold", m: 1 }} gutterBottom>
            {planName} - {planReport.location}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            p: 2
          }}
        >
          <Table aria-label="spanning table" >
            <TableHead>
              <TableRow>
                {header && header.map((head, idx) => (
                  <TableCell key={idx} align={head.align} sx={{ fontSize: 14, fontWeight: "bold", p: 1 }} >{head.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {planReport.plan && planReport.plan.map((item, rowIdx) => (
                <TableRow key={rowIdx}>
                  {header && header.map((head, colIdx) => (
                    <TableCell align={head.align} valign="middle" key={"cell_" + rowIdx + "_" + colIdx} sx={{ p: 0.5 }}>
                      {Array.isArray(item[head.key]) ?
                        (item[head.key].map((x, xidx) => (
                          xidx ?
                            <> <hr style={{ border: "dashed rgb(224, 224, 224)", borderWidth: 0.5 }} />
                              <div style={{ height: "3em" }}>{x} </div>
                            </> : <div style={{ height: "3em", verticalAlign: "middle" }}>{x} </div>
                        ))) : item[head.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Card>
    );
  }

  return (
    <>
      <Paper elevation={3} sx={{ top: "10px", p: 1 }}>
        <Box
          sx={{
            m: 1,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "baseline"
          }}
        >
          <Item>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
              <DatePicker
                label="Delivery Date"
                value={reportDeliveryDate}
                onChange={() => {

                }}
                onAccept={(newValue) => {
                  console.log(newValue.toISOString());
                  setReportDeliveryDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Item>
          <Item>
            <FormControl sx={{ width: 400 }}>
              <InputLabel id="plan-type-label">Plans</InputLabel>
              <Select
                labelId="plan-type-label"
                id="plan-type"
                multiple
                value={reportPlanTypes}
                onChange={handlePlanTypeSelectionChange}
                input={<OutlinedInput id="select-multiple-plan-types" label="Plans" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {plan_types.map((plan) => (
                  <MenuItem
                    key={plan.id}
                    value={plan.id}
                  >
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          <Item>
            <FormControl sx={{ width: 400 }}>
              <InputLabel id="location-label">Locations</InputLabel>
              <Select
                labelId="location-label"
                id="locations"
                multiple
                value={reportLocations}
                onChange={handleLocationSelectionChange}
                input={<OutlinedInput id="select-multiple-locations" label="Locations" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {locations.map((location) => (
                  <MenuItem
                    key={location.id}
                    value={location.id}
                  >
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          <Item>
            <Button
              variant="contained"
              color="success"
              onClick={fetchReports}
              disabled={loading}
            >
              Go
            </Button>
          </Item>
        </Box>
        <CircleLoader color={"#4A90E2"} loading={loading} css={override} size={100} margin={5} />
        <Box sx={{ minWidth: 275, width: "100%", m: 1, }} full width>
          {planReports && planReports.map((item, idx) => (
            <PlanReportCard planReport={item}
              header={headers[item.plan_type].headers}
              width={headers[item.plan_type].width}
              planName={headers[item.plan_type].name}
              key={"plan_" + idx} />
          ))}
          {!planReports.length &&
            <div>No Plans found!</div>
          }
        </Box>
      </Paper>
    </>
  );
}
