import { getDate, extractDate } from "../utils/date";
import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import AuthConsumer from "./auth";

const traceOrderContext = React.createContext({
  orderList: [],
  skipList: new Map(),
  orderRefresh: false,
  traceOrders: async () => { },
  generateTraceForOrders: async () => { },
})

function useTraceOrder() {
  const localDeliveryDate = JSON.parse(localStorage.getItem("deliveryDate")) || getDate()
  const [deliveryDate, setDeliveryDate] = useLocalStorage("deliveryDate", localDeliveryDate);
  const localOrderList = JSON.parse(localStorage.getItem("orderList")) || [];
  const [orderList, setOrderList] = useLocalStorage("orderList", localOrderList);
  const [orderRefresh, setOrderRefresh] = React.useState(false);
  const localSkipList = JSON.parse(localStorage.getItem("skipList")) || [];
  const [skipList, setSkipList] = useLocalStorage("skipList", localSkipList);

  const { getToken } = AuthConsumer();

  const traceOrders = async () => {
    /*
    GET
    Eval:
    https://2i6kc0i05c.execute-api.ap-south-1.amazonaws.com/trace-with-order-details?delivery_date=2021-11-24&includeActivities=true
    */
    return getToken()
      .then(authToken => {
        var requestOptions = {
          method: "GET",
          mode: "cors",
          headers: {
            "Authorization": "Bearer " + authToken
          },
        };
        const url = process.env.REACT_APP_TRACE_WITH_ORDER_DETAILS_URL
        return fetch(url + `?delivery_date=${extractDate(deliveryDate)}&include_activities=true`, requestOptions);
      })
      .then(response => response.json())
      .then(orders => {
        console.log(orders)
        for (let i = 0; i < orders.trace_order_details.length; i++) {
          orders.trace_order_details[i].activities = orders.trace_order_details[i].activities.sort((a, b) => a.date_time > b.date_time ? 1 : -1);
          try {
            orders.trace_order_details[i].order_detail.shipping_properties = JSON.parse(orders.trace_order_details[i].order_detail.shipping_address);
          } catch (err) {
            console.log(err)
            orders.trace_order_details[i].order_detail.shipping_properties = {
              first_name: "",
              last_name: "",
              address_1: orders.trace_order_details[i].order_detail.shipping_address,
              address_2: "",
              state: "",
              postcode: ""
            }
          }
        }
        setOrderList(orders.trace_order_details)
      })
      .catch(err => {
        console.log(err);
        setOrderList([]);
      });
  }

  const getOrderAudit = async (orderId) => {
    return getToken()
      .then(authToken => {
        var requestOptions = {
          method: "GET",
          mode: "cors",
          headers: {
            "Authorization": "Bearer " + authToken,
            "Content-Type": "application/json"
          },
        };
        const url = process.env.REACT_APP_TRACE_ORDER_AUDIT_URL
        return fetch(url + `?order_id=${orderId}`, requestOptions);
      })
      .then(async (response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw response
        }
      })
  }

  const postOrderAudit = async (orderId, activities) => {
    return getToken()
      .then(authToken => {
        var requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Authorization": "Bearer " + authToken,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "order_id": orderId,
            "activity_ids": activities.join(",")
          })
        };
        const url = process.env.REACT_APP_TRACE_ORDER_AUDIT_URL;
        return fetch(url, requestOptions);
      })
      .then(async (response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw response
        }
      })
  }

  const skipActivityMap = async (orderId) => {
    return getOrderAudit(orderId)
      .then(results => {
        console.log(results);
        let skipActivities = [];
        for (let i = 0; i < results.skipped_activities.length; i++) {
          const key = results.skipped_activities[i]["activity_id"];
          skipActivities.push(key);
        }
        return skipActivities;
      })
      .catch(error => {
        return error.json().then(err => {
          console.log(err)
          return [];
        });
      });
  }

  const updateSkipList = async () => {
    const nMap = [];
    for (let i = 0; i < orderList.length; i++) {
      const orderId = orderList[i].order_id;
      const skipActivities = await skipActivityMap(orderId);
      nMap.push(skipActivities);
    }
    setSkipList(nMap)
    console.log("complete update")
    return "ok";
  }


  const generateTraceForOrders = async () => {
    /*
    POST
    Eval:
    https://4qcsn3xvn5.execute-api.ap-south-1.amazonaws.com/order-tracing
    Dev:
    https://6qyf6moea4.execute-api.ap-south-1.amazonaws.com/order-tracing
    body: {
        "delivery_date" : "2021-11-25"
      }
    */
    console.log("delivery_date : " + extractDate(deliveryDate));
    return getToken()
      .then(authToken => {
        var requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Authorization": "Bearer " + authToken,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "delivery_date": extractDate(deliveryDate)
          })
        };
        const url = process.env.REACT_APP_ORDER_TRACING_URL
        return fetch(url, requestOptions);
      })
  }

  return {
    deliveryDate,
    setDeliveryDate,
    orderList,
    setOrderList,
    orderRefresh,
    setOrderRefresh,
    traceOrders,
    generateTraceForOrders,
    getOrderAudit,
    postOrderAudit,
    skipList,
    updateSkipList
  }
}

export function TraceOrderProvider({ children }) {
  const traceOrder = useTraceOrder();
  return (
    <traceOrderContext.Provider value={traceOrder}>
      {children}
    </traceOrderContext.Provider>
  )
}

export function TraceOrderConsumer() {
  return React.useContext(traceOrderContext);
}
