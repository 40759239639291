import * as React from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import AuthConsumer from "../contexts/auth";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import logo from '../images/Tralexho-O.svg';
import { Avatar } from '@mui/material';

export default function MenuAppBar() {
  const { user, logout } = AuthConsumer();
  const [anchorElUserMenu, setAnchorElUserMenu] = React.useState(null);
  const [anchorElAppMenu, setAnchorElAppMenu] = React.useState(null);
  const [selectedAppIndex, setSelectedAppIndex] = React.useState(1);

  //routing between pages
  const navigate = useNavigate();
  let location = useLocation();

  // useMemo is used to avoid the dependencies of this useEffect Hook change on every render.
  const pages = React.useMemo(() => [
    { name: "Activities", link: "/activities" },
    { name: "Adhoc Orders", link: "/adhoc-orders" },
    { name: "Trace Orders", link: "/trace-orders" },
    { name: "Plan Reports", link: "/plan-reports" },
    { name: "Print Orders", link: "/pdfview", skipMenu: true },
    { name: "Order Details", link: "/orders", skipMenu: true }
  ], []);

  // Update title when the pages are programmatically loaded using navigate function
  React.useEffect(() => {
    console.log("loading : " + location.pathname)
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].link === location.pathname) {
        setSelectedAppIndex(i);
        break;
      }
    }
  }, [location, pages])


  // Handle menu actions
  const handleOpenUserMenu = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null);
  };


  const handleOpenAppMenu = (event) => {
    setAnchorElAppMenu(event.currentTarget);
  };

  const handleCloseAppMenu = () => {
    setAnchorElAppMenu(null);
  };


  // Handle logout
  const handleLogout = () => {
    setAnchorElUserMenu(null);
    logout();
  }

  return (
    <React.Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Avatar sx={{ p: 0, mr: 0, background: "white" }} src={logo} alt="Logo" />
          {user && (
            <>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleOpenAppMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                sx={{ mt: '45px' }}
                anchorEl={anchorElAppMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElAppMenu)}
                onClose={handleCloseAppMenu}
              >
                {pages.map((page, index) =>
                (!page.skipMenu &&
                  <MenuItem
                    key={page.name}
                    selected={index === selectedAppIndex}
                    onClick={() => {
                      setSelectedAppIndex(index);
                      handleCloseAppMenu();
                      navigate(page.link);
                    }}
                  >
                    {page.name}
                  </MenuItem>
                )
                )}
              </Menu>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {selectedAppIndex > -1 ? (pages[selectedAppIndex].name) : ("")}
              </Typography>
              {user && (
                <Box sx={{ flexGrow: 0 }}>
                  <div>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenUserMenu}
                      color="inherit"
                      sx={{ p: 1, borderRadius: "50%", border: "2px solid white", width: 45 }}
                    >
                      <Typography variant="body1" component="div">
                        {user ? user.shortName : ""}
                      </Typography>
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      sx={{ mt: '45px' }}
                      anchorEl={anchorElUserMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUserMenu)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  </div>
                </Box>
              )}
            </>
          )}
          {!user && (
            <div>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Tralexho
              </Typography>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

