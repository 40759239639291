import * as React from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import { Auth, Hub } from "aws-amplify";

const authContext = React.createContext();

function useAuth() {
  const localUser = JSON.parse(localStorage.getItem('user')) || null;
  const [user, setUser] = useLocalStorage("user", localUser);
  let navigate = useNavigate();

  const login = () => {
    Auth.federatedSignIn({ provider: "Google" });
  }

  const logout = () => {
    Auth.signOut();
  }

  const getToken = async () => {
    const tokens =  await Auth.currentSession();
    console.log("obtained token")
    const token = tokens.idToken.jwtToken

    return token;
  }
  
  React.useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          break;
        case 'cognitoHostedUI':
          getUserData().then(userData => {

            // console.log(JSON.stringify(userData, null, 2));
            setUser(getUser(userData));
            navigate("/activities");
          });
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        case 'tokenRefresh':
          console.log('token refresh succeeded');
          getUserData().then(userData => {
            // console.log(JSON.stringify(userData, null, 2));
            setUser(getUser(userData));
          });
          break;
        case 'tokenRefresh_failure':
          console.log('token refresh failed');
          break;
        case 'configured':
          console.log('the Auth module is configured');
          break;
        default:
          console.log('received event : ' + event);
      }
    });

    getUserData().then(userData => {
      setUser(getUser(userData))
    });

    return () => {
      // unsubscribe here
      Hub.remove("auth", setUser(null))
    };
  // eslint-disable-next-line
  },[]) 

  function getUserData() {
    return Auth.currentUserInfo()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  function getUser(userData) {
    console.log(userData)
    if (userData === undefined || userData === null) {
      return null
    }
    const firstName = userData.attributes.given_name
    const lastName = userData.attributes.family_name
    const shortName = firstName.charAt(0) + lastName.charAt(0)
    const email = userData.attributes.email
    return  {
      shortName,
      email
    }
  }

  return {
    user,
    login,
    logout,
    getToken
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}