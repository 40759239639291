import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import AuthConsumer from "../contexts/auth";
import { extractDate } from "../utils/date";



export default function AdhocOrders() {
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [validOrders, setValidOrders] = useState(true);
    const [orders, setOrders] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const { getToken } = AuthConsumer();
    const sellerGLN = "8904368511210";

    const [status, setStatus] = useState("");

    const fetchOrders = async () => {
        setSubmitting(true);
        setStatus("submitting!");
        const authToken = await getToken();
        const adhocOrders = {
            "delivery_date": extractDate(deliveryDate),
            "seller_gln": sellerGLN,
            "order_ids": orders
        }
        console.log(adhocOrders)

        const s3FormData = new FormData();
        Object.keys(adhocOrders).forEach(key => s3FormData.append(key, adhocOrders[key]));

        const s3Url = process.env.REACT_APP_ORDERS_URL + "/adhoc-orders-s3";
        const s3Options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + authToken
            },
            body: s3FormData,
            redirect: "follow"
        }
        let s3ResponseJson = {}
        try {
            const s3Response = await fetch(s3Url, s3Options);
            s3ResponseJson = await s3Response.json();
        } catch (err) {
            console.log(err);
            setStatus("Error Occurred! Check orders and delivery date. Try again!");
            setSubmitting(false);
            return;
        }
        console.log(s3ResponseJson);
        if (s3ResponseJson["success"] !== undefined) {
            const saveOrders = {
                "delivery_date": extractDate(deliveryDate),
                "seller_gln": sellerGLN,
                "s3_file_name": s3ResponseJson["s3_file_name"]
            }
            console.log(saveOrders);
            const saveFormData = new FormData();
            Object.keys(saveOrders).forEach(key => saveFormData.append(key, saveOrders[key]));

            const saveOrdersUrl = process.env.REACT_APP_ORDERS_URL + "/adhoc-orders";
            const saveOrdersOptions = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": "Bearer " + authToken
                },
                body: saveFormData,
                redirect: "follow"
            }
            let saveOrdersResponseJson = {}

            try {
                const saveOrdersResponse = await fetch(saveOrdersUrl, saveOrdersOptions);
                saveOrdersResponseJson = await saveOrdersResponse.json();
                console.log(saveOrdersResponseJson);
            } catch (err) {
                console.log(err);
                setStatus("Error Occurred while saving! Try again!")
                setSubmitting(false);
                return;
            }
            
            const updatePlanUrl = process.env.REACT_APP_SOURCING_PLANNER_URL + "/process-orders";
            const updatePlan = {
                "folder-date": extractDate(deliveryDate),
                "plan_generation_only": "true",
                "date_type": "delivery",
            }
            const updatePlanFormData = new FormData();
            Object.keys(updatePlan).forEach(key => updatePlanFormData.append(key, updatePlan[key]));
            const updatePlanOptions = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": "Bearer " + authToken
                },
                body: updatePlanFormData,
                redirect: "follow"
            }

            let updatePlanResponseJson = {}

            try {
                const updatePlanResponse = await fetch(updatePlanUrl, updatePlanOptions);
                updatePlanResponseJson = await updatePlanResponse.json();
                console.log(updatePlanResponseJson);
                if (updatePlanResponseJson["success"] === undefined) {
                    setStatus("Error Occurred while updating plan! Try again!")
                    setSubmitting(false);
                    return;
                }
            } catch (err) {
                console.log(err);
                setStatus("Error Occurred while updating plan! Try again!")
                setSubmitting(false);
                return;
            }

            setStatus("Success!")
            
        }

        setSubmitting(false);
    }

    const isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    useEffect(() => {
        const checkOrders = () => {
            const orderList = orders.split(",");
            for (const order of orderList) {
                console.log(order, isNumeric(order))
                if (!isNumeric(order)) {
                    setValidOrders(false);
                    return;
                }
            }
            console.log("completed check")
            setValidOrders(true);
        }
        checkOrders();

    }, [orders])

    return (
        <Stack spacing={2} alignItems={"center"} justifyContent={"center"} sx={{ m: 5 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker
                    label="Delivery Date"
                    value={deliveryDate}
                    onChange={() => {

                    }}
                    onAccept={(newValue) => {
                        setDeliveryDate(newValue);
                    }}
                    renderInput={(params) => <TextField sx={{ width: 400 }} {...params} />}
                />
            </LocalizationProvider>

            <TextField
                disabled
                id="outlined-disabled"
                label="Seller GLN"
                defaultValue={sellerGLN}
                sx={{ width: 400 }}
            />
            <TextField
                id="outlined-textarea"
                label="WooCommerce Order Ids (comma separated)"
                placeholder="Enter WooCommerce Order Ids separated by comma."
                multiline
                rows={4}
                sx={{ width: 400 }}
                onChange={(evt) => {
                    setOrders(evt.target.value)
                }}
                helperText={!validOrders ? "Incorrect Entry" : ""}
                error={!validOrders}
            />
            <Button variant="contained" disabled={submitting || !validOrders} onClick={() => fetchOrders()}>
                <Typography variant="button">Submit</Typography>
            </Button>

            {submitting &&
             <LinearProgress sx={{ width: 300 }} />
            }
            <Typography variant="caption">{status}</Typography>
            

        </Stack>
    )
}