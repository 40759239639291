import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { getDate, extractDate } from "../utils/date";
import AuthConsumer from "./auth";


const activityContext = React.createContext({
  loading: false,
  activityList: [],
  dateRange: [],
  getActivities: () => { },
  postActivity: async () => { },
});

function useActivity() {
  const localDateRange = JSON.parse(localStorage.getItem('dateRange')) ||
    [getDate(), getDate()];
  const [dateRange, setDateRange] = useLocalStorage("dateRange", localDateRange);

  const localActivityList = JSON.parse(localStorage.getItem('activityList')) || [];
  const [activityList, setActivityList] = useLocalStorage("activityList", localActivityList);
  const { getToken } = AuthConsumer();

  // getActivities 
  const getActivities = () => {
    console.log("calling getActivities")
    return getToken()
      .then(authToken => {
        console.log(dateRange);
        const startDate = extractDate(dateRange[0]);
        const endDate = extractDate(dateRange[1]);
        const url = process.env.REACT_APP_ACTIVITIES_URL
        return fetch(url + "?from=" + startDate + "&to=" + endDate + "&locations=&activities=", {
          method: "GET",
          mode: "cors",
          headers: {
            "Authorization": "Bearer " + authToken
          }
        })
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.message !== "Error fetching activities") {
          const activities = data.activities.sort((a,b) => a.date_time > b.date_time? 1: -1);
          setActivityList(activities);
        }
      });
  };

  const postActivity = async (data) => {
    console.log(data)
    return getToken()
      .then(authToken => {
        var formdata = new FormData();
        formdata.append("activity_type", data.activity_type);
        formdata.append("location_id", data.location_id);
        formdata.append("location_name", data.location_name);
        formdata.append("date_time", data.date_time);
        formdata.append("photo_data_uri", data.photo_data_uri);
        formdata.append("comments", data.comments);
        var requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Authorization": "Bearer " + authToken
          },
          body: formdata,
          redirect: "follow"
        };
        const url = process.env.REACT_APP_ACTIVITIES_URL
        return fetch(url, requestOptions)
      })
      .then(response => {
        return response.json()
      });
  }

  return {
    dateRange,
    setDateRange,
    activityList,
    setActivityList,
    getActivities,
    postActivity
  }
}

export function ActivityProvider({ children }) {
  const activity = useActivity();
  
  return (
    <activityContext.Provider value={activity}>
      {children}
    </activityContext.Provider>
  )
}

export function ActivityConsumer() {
  return React.useContext(activityContext);
}
