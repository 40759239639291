import { React } from "react";
import { Font, Page, Text, View, Document, Image } from "@react-pdf/renderer";
import RobotoBold from "../fonts/Roboto/Roboto-Bold.ttf";
import RobotoRegular from "../fonts/Roboto/Roboto-Regular.ttf";
import RobotoItalic from "../fonts/Roboto/Roboto-Italic.ttf";


function MiniDocument(props) {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: RobotoRegular,
      },
      {
        src: RobotoItalic,
        fontStyle: 'italic'
      },
      {
        src: RobotoBold,
        fontWeight: 'bold'
      },
    ]
  });
  const orderList = props.ol;

  const imageURL = (order_id) => {
    const encodedUrl = `${window.location.origin}/orders?order_id=${order_id}`
    console.log(encodedUrl);
    return `https://quickchart.io/chart?cht=qr&chl=${encodedUrl}&chs=180x180&choe=UTF-8&chld=L|0`;
  }

  const createTableRows = (item) => {
    return (
      <>
        {/* row1 */}
        <View style={mainTableRowStyle}>
          <View style={viewScanTextStyle}>
            <Text style={scanTextStyle}>Scan QR code to trace your seafood's journey!</Text>
          </View>
          <View style={tableQrCodeColStyle}>
            <Image
              src={imageURL(item.order_id)}
              style={qr}
              crossOrigin="anonymous"
            />
          </View>

          <View style={tableColStyle}>
            <View style={tableRowStyle}>
              <Image
                src="https://api.startupindia.gov.in/sih/api/file/user/image/Startup?fileName=ffd2894d-a625-41e3-bafe-b88b4d293289.png"
                style={companyLogo}
                crossOrigin="anonymous"
              />
            </View>
            <View style={tableRowStyle}>
              <View style={tableRowOrderDetailsStyle}>
                <Text style={orderIdCellStyle}>Order ID: {item.order_id}</Text>
                <Text style={customerIdCellStyle}>
                  {item.order_detail.shipping_properties.first_name} {item.order_detail.shipping_properties.last_name} (ID: {item.order_detail.customer_id})
                </Text>
                {/* <Text style={customerIdCellStyle}>
                  Customer (ID): {item.order_detail.shipping_properties.first_name} {item.order_detail.shipping_properties.last_name} ({item.order_detail.customer_id})
                </Text> */}
                <Text style={tableCellAddressStyle}>
                  {item.order_detail.shipping_properties.address_1}, {item.order_detail.shipping_properties.address_2}, {item.order_detail.shipping_properties.city}, {item.order_detail.shipping_properties.state}, {item.order_detail.shipping_properties.postcode}
                </Text>

              </View>
            </View>
          </View>

        </View>
        {/* row2 */}

        <View style={colspan}>
          <Text style={H4Style}>Order Items</Text>
          {item.order_line_item.map((i, index) => (
            <div
              style={{ display: "flex", flexDirection: "row" }}
              key={"t-" + i.line_item_id}
            >
              <div style={{ width: "4%", paddingRight: 1 }}>
                <Text style={orderLineItemStyle}>{(index + 1)}. </Text>
                {/* SKU need not be displayed in the tracing slip
                <Text style={orderLineItemStyle}>({i.sku})</Text> */}
              </div>
              <div style={{ width: "55%", paddingRight: 2 }}>
                <Text style={orderLineItemStyle}>{i.line_item_name == "" ? "Common Box - Every Tuesday" : i.line_item_name}</Text>
                {/* SKU need not be displayed in the tracing slip
                <Text style={orderLineItemStyle}>({i.sku})</Text> */}
              </div>
              <div style={{ width: "15%" }}>
                <Text style={orderLineItemStyle}>{i.quantity} Box(es)</Text>
              </div>
              <div style={{ width: "25%" }}>
                <Text style={orderLineItemStyle}>Rs. {i.price}/-  per Box</Text>
              </div>
            </div>
          ))}
        </View>
      </>
    );

  };

  return (
    <Document>
      {orderList &&
        orderList.map((item, index) => (
          <Page
            style={pageStyle}
            size="A7"
            orientation="landscape"
            key={item.order_id}
          >
            <View style={tableStyle} >{createTableRows(item)}</View>
          </Page>
        ))}
    </Document>
  );
}

const orderLineItemStyle = {
  textAlign: "left",
  margin: 0,
  marginLeft: 0,
  fontSize: 9,
  fontFamily: "Roboto",
};

const companyLogo = {
  height: "29.8582677165px",
  width: "118.8661417323px",
  marginTop: -7.4,
  marginLeft: -10
};

const pageStyle = {
  paddingTop: 1,
  paddingHorizontal: 4,
  paddingBottom: 5,
};

const qr = {
  height: "100px",
  width: "100px",
  //marginLeft: "5px",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  margin: 0,
  padding: 0
};

const H4Style = {
  fontFamily: "Roboto",
  fontSize: 9,
  fontWeight: "bold",
  marginTop: 2,
  // marginLeft: 10,
  marginBottom: 2,
  textAlign: "left",
}

const tableStyle = {
  display: "table",
  width: "850px",
  height: "auto",
};

const tableRowStyle = {
  flexDirection: "row",
  position: "relative"
};

const mainTableRowStyle = {
  flexDirection: "row",
  position: "relative",
  marginLeft: 10,
  marginRight: 10,
  marginTop: 3
};

const colspan = {
  width: "272px",
  marginLeft: 10,
  marginRight: 10
};

const tableColStyle = {
  width: "150px",
  left: "125px",
  border: "none",
  // backgroundColor: "red",
  // textAlign: "left",
  position: "absolute",
};

const tableRowOrderDetailsStyle = {
  width: "95%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  margin: 0,
  padding: 0,
};

const tableQrCodeColStyle = {
  width: "100px",
  borderStyle: "none",
  textAlign: "left",
  padding: 0,
  transform: "translate(-80,0)"
};

const orderIdCellStyle = {
  fontFamily: "Roboto",
  textAlign: "right",
  marginBottom: 4,
  fontSize: 16,
  fontWeight: 'bold',
};

const customerIdCellStyle = {
  fontFamily: "Roboto",
  textAlign: "left",
  margin: 1,
  fontSize: 9,
  fontWeight: 'bold',
}

const viewScanTextStyle = {
  width: 100,
  height: 25,
  borderStyle: "solid",
  borderColor: "white",
  // borderBottomWidth: 0,
  borderWidth: 1,
  transform: "rotate(-90deg)translate(-40,-40)",
}

const scanTextStyle = {
  fontFamily: "Roboto",
  textAlign: "center",
  fontWeight: 'bold',
  fontSize: 9,
  margin: 0,
  padding: 0,

}

const tableCellAddressStyle = {
  fontFamily: "Roboto",
  textAlign: "left",
  margin: 1,
  fontSize: 9,
  maxWidth: 300,
  width: "auto"
};

export default MiniDocument;
