import React, { useState } from "react";
import Camera, { IMAGE_TYPES, FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import ImagePreview from "./ImagePreview";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "./ActivityForm.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { ActivityConsumer } from "../contexts/activity";
import { toIsoString } from "../utils/date";
import { css } from "@emotion/react";
import CircleLoader from "react-spinners/CircleLoader";

const override = css`
  position:fixed;
  left: 50%;
  margin-left: -4em;
  top: 50%;
  border: 2px;
`;


function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        borderRadius: 1,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function ActivityForm() {
  const activities = [
    {
      id: "act0",
      name: "",
    },
    {
      id: "act1",
      name: "Sourcing",
    },
    {
      id: "act2",
      name: "Processing",
    },
    {
      id: "act3",
      name: "Dispatching",
    },
    {
      id: "act4",
      name: "Distribution",
    },
  ];

  const locations = [
    {
      id: "loc0",
      name: "",
    },
    {
      id: "5.001",
      name: "CHOMBALA HARBOUR",
    },
    {
      id: "5.002",
      name: "KORAPUZHA",
    },
    {
      id: "5.003",
      name: "CHALIYAM HARBOUR",
    },
    {
      id: "5.004",
      name: "BEYPORE HARBOUR",
    },
    {
      id: "5.005",
      name: "VELLAYIL HARBOUR",
    },
    {
      id: "5.006",
      name: "PUTHIYAPPA HARBOUR",
    },
    {
      id: "5.007",
      name: "ELATHUR BEACH",
    },
    {
      id: "5.008",
      name: "KOYILANDI HARBOUR",
    },
    {
      id: "5.009",
      name: "VARAPUZHA MARKET",
    },
    {
      id: "6.001",
      name: "TRALEXHO PROCESSING CENTRE, KOZHIKODE",
    },
    {
      id: "6.002",
      name: "VFH PROCESSING CENTRE, VARAPUZHA",
    },
    {
      id: "7.001",
      name: "TRALEXHO DISPATCH HUB, KOZHIKODE",
    },
    {
      id: "7.002",
      name: "VFH DISPATCH HUB, VARAPUZHA",
    },
    {
      id: "1.003",
      name: "TRALEXHO DISTRIBUTION HUB, BANGALORE"
    }
  ];

  const { postActivity } = ActivityConsumer();
  const [photoDataUri, setPhotoDataUri] = useState("");
  const [avatarSrc, setAvatarSrc] = useState();
  const [openCamera, setOpenCamera] = useState(false);
  const [idealFacingMode, setIdealFacingMode] = useState(FACING_MODES.ENVIRONMENT);
  const [activityId, setActivityId] = useState("act0");
  const [locationId, setLocationId] = useState("loc0");
  const [comments, setComments] = useState("");
  const isFullscreen = false;
  const [submitPrompt, setSubmitPrompt] = useState(false);
  const [submitSuccessPrompt, setSubmitSuccessPrompt] = useState(false);
  const [submitFailPrompt, setSubmitFailPrompt] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleTakePhotoAnimationDone(dataUri) {
    console.log("takePhoto");
    setPhotoDataUri(dataUri);
  }
  function handleComments(event) {
    setComments(event.target.value);
  }
  function cancel() {
    setPhotoDataUri("");
    setOpenCamera(false);
  }
  function ok() {
    setAvatarSrc(photoDataUri);
    setPhotoDataUri("");
    setOpenCamera(false);
  }

  function toggleCameraMode() {
    setIdealFacingMode(idealFacingMode === FACING_MODES.ENVIRONMENT ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT);
    console.log("idealFacingMode : " + idealFacingMode);
  }


  function open() {
    setPhotoDataUri("");
    setOpenCamera(true);
  }
  const handleActivity = (event) => {
    console.log(event.target.value);
    setActivityId(event.target.value);
  };
  const changeLocation = (event) => {
    console.log(event.target.value);
    setLocationId(event.target.value);
  };

  function save() {
    const img = avatarSrc !== undefined ? avatarSrc.replace("data:image/jpeg;base64,", "") : ""
    const activityObj = activities.find(elem => elem.id === activityId)
    const activityName = activityObj !== undefined ? activityObj.name : ""

    const locationObj = locations.find(elem => elem.id === locationId)
    const locationName = locationObj !== undefined ? locationObj.name : ""
    console.log("get", locationName, activityName);


    const data = {
      activity_type: activityName,
      location_id: locationId,
      location_name: locationName,
      comments: comments,
      photo_data_uri: img,
      date_time: toIsoString(new Date()),
    };

    setSubmitPrompt(false);
    setLoading(true);
    postActivity(data)
      .then(result => {
        console.log(result);
        clear();
        setLoading(false);
        setSubmitSuccessPrompt(true);
      })
      .catch(error => {
        console.log('error', error)
        setLoading(false);
        setSubmitFailPrompt(true);
      });
  }

  const clear = () => {
    setAvatarSrc("");
    setActivityId("");
    setLocationId("");
    setComments("");
  };

  const handleClickOpen = () => {
    setSubmitPrompt(true);
  };

  const navigate = useNavigate();

  const back = () => {
    setSubmitSuccessPrompt(false);
    navigate("/activities");
  };

  const handleAgree = () => {
    save();
  };

  const handleDisagree = () => {
    setSubmitFailPrompt(false);
    setSubmitPrompt(false);
  };

  return (
    <div>
      <CircleLoader color={"#4A90E2"} loading={loading} css={override} size={100} margin={5} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Item sx={{ m: "1px" }}>
          <h3>Activity Form</h3>
        </Item>

      </Box>


      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          bgcolor: "background.paper",
        }}
      >

        <Item>
          <TextField
            id="activity-name"
            select
            label="Activity Name"
            required
            value={activityId}
            onChange={handleActivity}
            margin="normal"
            size="small"
            variant="standard"
            fullWidth
            sx={{ width: "200px" }}
          >
            {activities.map((option) => (
              <MenuItem key={option.id} value={option.id} name={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Item>
        <Item>
          <TextField
            id="location"
            label="Location"
            select
            required
            value={locationId}
            onChange={changeLocation}
            margin="normal"
            size="small"
            variant="standard"
            fullWidth
            sx={{ width: "200px" }}
          >
            {locations.map((option) => (
              <MenuItem key={option.id} value={option.id} name={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Item>
        <Item>
          <TextareaAutosize
            id="comments"
            aria-label="comments"
            minRows={3}
            placeholder="comments..."
            style={{ width: 200 }}
            value={comments}
            onChange={handleComments}
          />
        </Item>
        <Item>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Take a Photo
          </Typography>
          <AddAPhotoIcon onClick={open} data-testid="AddAPhotoIcon" />
        </Item>
        <Item>
          {openCamera === false && <img src={avatarSrc} style={{ width: "250px", borderRadius: "5%" }} />}
        </Item>
        {openCamera === true && photoDataUri ? (
          <>
            <ImagePreview dataUri={photoDataUri} isFullscreen={isFullscreen} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Item>
                <CheckIcon onClick={ok} />
              </Item>
              <Item>
                <CloseIcon onClick={cancel} />
              </Item>
            </Box>
          </>
        ) : openCamera === true ? (
          <>
            <Camera
              onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
              isFullscreen={isFullscreen}
              imageType={IMAGE_TYPES.JPG}
              idealFacingMode={idealFacingMode}
              imageCompression={0.97}
              isMaxResolution={true}
              isImageMirror={idealFacingMode === FACING_MODES.ENVIRONMENT ? false : true}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                bgcolor: "background.paper",
              }}
            >
              <Item>
                <CloseIcon onClick={cancel} />
              </Item>
              <Item>
                <CameraswitchIcon onClick={toggleCameraMode} />
              </Item>
            </Box>
          </>
        ) : null}
        <Item>

          <Button
            id="cancel-button"
            variant="contained"
            fullWidth
            style={{ width: 100, marginRight: "10px" }}
            onClick={back}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            fullWidth
            style={{ width: 100, marginLeft: "10px" }}
            onClick={handleClickOpen}
            id="submit-button"
            disabled={(locationId == "loc0" | activityId == "act0" | openCamera) ? true : false}
          >
            Submit
          </Button>

        </Item>

      </Box>

      <div>
        <Dialog
          open={submitPrompt}
          onClose={handleDisagree}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Submit Activity Form"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to submit the form?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDisagree}>Disagree</Button>
            <Button onClick={handleAgree} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={submitSuccessPrompt}
          onClose={back}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Submit Activity Form"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Activity Successfully Submitted!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="ok-button" onClick={back}>Ok</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={submitFailPrompt}
          onClose={handleDisagree}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Submit Activity Form"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Activity Submission Failed!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDisagree}>Ok</Button>
          </DialogActions>
        </Dialog>
      </div>

    </div>
  );
}

export default ActivityForm;
