import * as React from "react";
import { CollapsibleTableRow } from "./CollapsibleTable";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { css } from "@emotion/react";
import CircleLoader from "react-spinners/CircleLoader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { Typography } from "@mui/material";
import banner from '../images/Tralexho-Main-Banners-Technology.png';

const override = css`
  position:fixed;
  left: 50%;
  margin-left: -4em;
  top: 50%;
  border: 2px;
`;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PublicOrderUI() {
  let query = useQuery();
  const orderId = query.get("order_id");
  const [rows, setRows] = React.useState({ activities: [] });
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true);
    getOrderTrace(orderId)
      .then(async (response) => {
        setLoading(false)
        if (response.ok) {
          return response.json()
        } else {
          throw response
        }
      })
      .then(result => {
        console.log(result);
        setLoading(false);
        const activities = result.activities.sort((a,b) => a.date_time > b.date_time? 1: -1);
        setRows({ activities : activities })
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        error.json().then(err => {
          console.log(err)
          setRows({ activities: [] })
        })
      })
      .finally(() => {

      });
  }, [orderId]);

  const getOrderTrace = async (order_id) => {
    /*
    GET
    https://5uy1qb5gtc.execute-api.ap-south-1.amazonaws.com/trace-order-activities?order_id=1
    */
    console.log(order_id);

    var requestOptions = {
      method: "GET",
      mode: "cors",
    };
    const url = process.env.REACT_APP_TRACE_ORDER_ACTIVITIES_URL
    return fetch(url + `?order_id=${order_id}`, requestOptions);
  }

  return (
    <TableContainer component={Paper} sx={{p:0}}>
      <CircleLoader color={"#4A90E2"} loading={loading} css={override} size={100} margin={5} />
      <img src={banner} style={{width:"100vw", margin: 0, padding:0, borderRadius:0}} />
      <Typography variant="h5" color={"#4A90E2"}>Tracing the journey of your seafood order!</Typography>
      {(rows.activities && rows.activities.length > 0) &&
        <Table aria-label="collapsible table" size="small">
          <TableBody>
            {rows.activities.map((row) => (
              <CollapsibleTableRow key={row.activity_id} row={row} />
            ))}
          </TableBody>
        </Table>
      }
      <Typography variant="caption">Copyright 2022 © Tralexho All rights reserved</Typography>
    </TableContainer>
  );
}
