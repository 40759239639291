import { React } from "react";
import { Font, Page, Text, View, Document, Image, Circle, Svg, Line, Path, G } from "@react-pdf/renderer";
import { toISTFormatString } from "../utils/date";
import RobotoBold from "../fonts/Roboto/Roboto-Bold.ttf";
import RobotoRegular from "../fonts/Roboto/Roboto-Regular.ttf";
import RobotoItalic from "../fonts/Roboto/Roboto-Italic.ttf";
import TralexhoX from "../images/Tralexho-X.png";
import tagline from "../utils/tagline";

function MyDocument(props) {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: RobotoRegular,
      },
      {
        src: RobotoItalic,
        fontStyle: 'italic'
      },
      {
        src: RobotoBold,
        fontWeight: 'bold'
      },
    ]
  });
  const orderList = props.ol;
  const skipList = props.sl;

  const imageURL = (order_id) => {
    const encodedUrl = `${window.location.origin}/orders?order_id=${order_id}`
    console.log(encodedUrl);
    return `https://quickchart.io/chart?cht=qr&chl=${encodedUrl}&chs=180x180&choe=UTF-8&chld=L|0`;
  }

  const createTableRows = (item, skipActivities) => {
    return (
      <>
        {/* row1 */}
        <View style={tableRowStyle} key={"t-" + item.order_id}>
          <View style={viewScanTextStyle}>
            <Text style={scanTextStyle}>Scan QR code to trace the journey of your seafood</Text>
          </View>
          <View style={tableQrCodeColStyle}>
            <Image
              src={imageURL(item.order_id)}
              style={qr}
              crossOrigin="anonymous"
            />
          </View>

          <View style={tableColStyle}>
            <View style={tableRowStyle}>
              <View style={firstTableColHeaderStyle}>
                <Image
                  src="https://api.startupindia.gov.in/sih/api/file/user/image/Startup?fileName=ffd2894d-a625-41e3-bafe-b88b4d293289.png"
                  style={companyLogo}
                  crossOrigin="anonymous"
                />
              </View>
            </View>
            <View style={tableRowStyle}>
              <View style={tableRowOrderDetailsStyle}>
                <Text style={orderIdCellStyle}>Order ID: {item.order_id}</Text>
                <Text style={customerIdCellStyle}>
                  Customer (ID): {item.order_detail.shipping_properties.first_name} {item.order_detail.shipping_properties.last_name} ({item.order_detail.customer_id})
                </Text>
                <Text style={tableCellAddressStyle}>
                  {item.order_detail.shipping_properties.address_1}, {item.order_detail.shipping_properties.address_2}, {item.order_detail.shipping_properties.city},{item.order_detail.shipping_properties.state},{item.order_detail.shipping_properties.postcode}
                </Text>

              </View>
            </View>
          </View>

        </View>
        {/* row2 */}

        <View style={colspan}>
          <Text style={H4Style}>Order Items</Text>
          {item.order_line_item.map((i, index) => (
            <div
              style={{ display: "flex", flexDirection: "row" }}
              key={"t-" + i.line_item_id}
            >
              <div style={{ width: "50%" }}>
                <Text style={orderLineItemStyle}>{(index + 1)}. {i.line_item_name}</Text>
                {/* SKU need not be displayed in the tracing slip
                <Text style={orderLineItemStyle}>({i.sku})</Text> */}
              </div>
              <div style={{ width: "25%" }}>
                <Text style={orderLineItemStyle}>{i.quantity} Box(es)</Text>
              </div>
              <div style={{ width: "25%" }}>
                <Text style={orderLineItemStyle}>Rs. {i.price}/-  per Box</Text>
              </div>
            </div>
          ))}
        </View>

        {/* row3 */}
        <Text style={H4Style}> Tracing the journey of your seafood order! </Text>

        {item.activities.map((i, index) => (
          <div key={i.activity_id + "y"}>
            {skipActivities.indexOf(i.activity_id) < 0 &&
              <View style={secondTableRowStyle} key={i.activity_id} wrap={false}>
                <View style={firstTableColStyle}>
                  {i.thumbnail_presigned_url != "" && (
                    <Image
                      src={i.thumbnail_presigned_url}
                      style={imageStyle}
                      crossOrigin="anonymous" />
                  )}

                </View>

                <View style={secondTableColStyle}>
                  <Svg viewBox="0 0 330 80">
                    {index > 0 &&
                      <Line
                        x1="15.455"
                        y1="0"
                        x2="15.455"
                        y2="28"
                        strokeWidth={1}
                        stroke="#242424" />}

                    <Line
                      x1="15.455"
                      y1="45"
                      x2="15.455"
                      y2="100"
                      strokeWidth={1}
                      stroke="#242424" />

                    <G transform="translate(3.5,25) scale(0.75)">
                      <Circle cx="15.455" cy="15.455" r="12.255" stroke="#242424" strokeWidth="6.4" />
                      <Path d="M12.4379 21.2036L12.8817 13.9543L18.9379 9.9453L18.4941 17.1946L12.4379 21.2036Z" fill="#FF1212" />
                    </G>

                    {/* <Text x="45" y="15" style={{ fontFamily: "Roboto", fontSize: 11 }}>
                      {i.activity_type}
                    </Text> */}

                    <Text x="45" y="15" style={{ fontFamily: "Roboto", fontStyle: "italic", fontSize: 11 }}>
                      {tagline[i.activity_type]}
                    </Text>

                    <Text x="45" y="30" style={{ fontFamily: "Roboto", fontWeight: "bold", fontSize: 11, fill: "#4A90E2" }}>
                      {i.location_name}
                    </Text>

                    <Text x="45" y="45" style={{ fontFamily: "Roboto", fontSize: 11 }}>
                      {toISTFormatString(i.date_time)}
                    </Text>

                    <Text x="45" y="60" style={{ fontFamily: "Roboto", fontSize: 11 }}>
                      {i.comments}
                    </Text>

                  </Svg>
                </View>
              </View>
            }
          </div>
        ))}
        <View style={secondTableRowStyle} key="yourhome" wrap={false}>
          <View style={firstTableColStyle}>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }} >
            <Image src={TralexhoX} style={{ width: "25px", height: "25px", marginLeft: "18px", borderWidth: 0 }} />
            <View style={{ display: "flex", flexDirection: "column", marginLeft: "15px" }} >
              <Text style={{ fontFamily: "Roboto", fontWeight: "bold", fontSize: 11, color: "#4A90E2" }}>
                Your Home!
              </Text>

              <Text x="45" y="55" style={{ fontFamily: "Roboto", fontStyle: "italic", fontSize: 11 }}>
                {tagline["Home"]}
              </Text>
            </View>
          </View>
        </View>
        <View fixed style={{ fontFamily: "Roboto", position: 'absolute', fontSize: 9, top: 755, left: 0, right: 0, maxWidth: "520", }}>
          <Text>Copyright 2022 © Tralexho All rights reserved</Text>
          <Text style={{ fontWeight: "bold" }}>IMPORTANT GUIDELINE: Recommended to cook as soon as you
            receive the product to enjoy the fresh taste or store it between 0-4&deg;c for
            later use. Consume within three days from the packed date.
          </Text>

          <View style={{ borderBottomColor: 'black', borderBottomWidth: 0.5, maxWidth: "520", padding:0, marginBottom:2, marginTop: 2 }} />
          <Text style={{ fontFamily: "Roboto", fontSize: 9, maxWidth: "520", paddingTop: 0, paddingBottom: 0, borderWidth: 0 }}>
            <Text style={{ fontWeight: "bold" }}>Registered office:</Text> TRALEXHO Ecommerce Pvt Ltd, Novel Tech Park, GB Palya, Bangalore – 560068 &nbsp;
            <Text style={{ fontWeight: "bold" }}>FSSAI No.:</Text> 11221334001279 
            <Text style={{ fontWeight: "bold" }}>GSTIN No.:</Text> 29AAICT6232A1ZH  &bull; 
            <Text style={{ fontWeight: "bold" }}> Branch Office & Processing Centre:</Text> TRALEXHO Ecommerce Pvt Ltd, 4/370H, IQ tower, Eranjikkal PO, Calicut - 673303 
            <Text style={{ fontWeight: "bold" }}> FSSAI No.:</Text> 11322011000919  &bull; 
            <Text style={{ fontWeight: "bold" }}> Feedback at:</Text> customercare@tralexho.com 
          </Text>
        </View>
      </>
    );

  };

  return (
    <Document>
      {orderList &&
        orderList.map((item, index) => (
          <Page
            style={pageStyle}
            size="A4"
            orientation="portrait"
            key={item.order_id}
          >
            <View style={tableStyle} >{createTableRows(item, skipList[index])}</View>
          </Page>
        ))}
    </Document>
  );
}

const orderLineItemStyle = {
  textAlign: "left",
  margin: 1,
  marginLeft: 40,
  fontSize: 11,
  fontFamily: "Roboto",
};

const companyLogo = {
  height: "59.71653543307087px",
  width: "237.7322834645669px",
  margin: "auto",
};
const imageStyle = {
  width: "50px",
  height: "50px",
  margin: "auto",
};
const pageStyle = {
  paddingTop: 16,
  paddingHorizontal: 40,
  paddingBottom: 56,
};

const qr = {
  height: "135px",
  width: "135px",
  //marginLeft: "5px",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  margin: 0,
  padding: 1
};

const H4Style = {
  fontFamily: "Roboto",
  fontSize: 14,
  fontWeight: "bold",
  marginTop: 10,
  marginLeft: 10,
  marginBottom: 5,
}

const tableStyle = {
  display: "table",
  width: "850px",
  height: "auto",
};

const tableRowStyle = {
  flexDirection: "row",
  position: "relative"
};


const secondTableRowStyle = {
  marginLeft: "40px",
  flexDirection: "row",
  position: "relative",
  justifyContent: "flex-start"
};

const firstTableColHeaderStyle = {
  width: "55%",
  height: "60px",
  borderStyle: "solid",
  borderColor: "white",
  // borderBottomWidth: 0,
  borderWidth: 1,
  backgroundColor: "white",
  margin: 0,
  padding: 0
};

const colspan = {
  width: "60%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  backgroundColor: "#ffffff",
  alignItem: "center",
  borderBottomWidth: 0.5,
  borderBottomColor: "gray",
  borderTopWidth: 0.5,
  borderTopColor: "gray",
  paddingBottom: "10px",
  marginTop: "5px"
};

const firstTableColStyle = {
  width: "12%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  padding: 5,
  // borderRightWidth: 0.5,
  // borderRightColor: "lightgray",
};

const secondTableColStyle = {
  width: "40%",
  height: "70px",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  // borderBottomWidth: 0.5,
  // borderBottomColor: "lightgray",
};

const tableColStyle = {
  width: "80%",
  border: "none",
  textAlign: "left",
  transform: "translate(-60,0)"
};

const tableRowOrderDetailsStyle = {
  width: "auto",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  //borderBottomWidth: 0.5,
  //borderBottomColor: "gray",
  textAlign: "left",
};

const tableQrCodeColStyle = {
  width: "16%",
  borderStyle: "none",
  textAlign: "left",
  padding: 0,
  transform: "translate(-80,0)"
};

const orderIdCellStyle = {
  fontFamily: "Roboto",
  textAlign: "left",
  margin: 1,
  marginBottom: 6,
  fontSize: 14,
  fontWeight: 'bold',
};

const customerIdCellStyle = {
  fontFamily: "Roboto",
  textAlign: "left",
  margin: 1,
  fontSize: 12,
  fontWeight: 'bold',
}

const viewScanTextStyle = {
  width: 120,
  height: 25,
  borderStyle: "solid",
  borderColor: "white",
  // borderBottomWidth: 0,
  borderWidth: 1,
  transform: "rotate(-90deg)translate(-60,-40)",
}

const scanTextStyle = {
  fontFamily: "Roboto",
  textAlign: "center",
  fontWeight: 'bold',
  fontSize: 9,
  margin: 0,
  padding: 0,

}

const tableCellAddressStyle = {
  fontFamily: "Roboto",
  textAlign: "left",
  margin: 1,
  fontSize: 11,
  maxWidth: 300,
  width: "auto"
};

export default MyDocument;
