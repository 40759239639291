import React from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import MiniDocument from "./MiniDocument";
import { TraceOrderConsumer } from "../contexts/trace-order";
import { extractDate } from "../utils/date";

export default function MiniPdfModal() {
  const { orderList, deliveryDate } = TraceOrderConsumer();

  return (
    <>
      <div style={downloadStyle}>
        <PDFDownloadLink document={<MiniDocument ol={orderList}/>} fileName={ "Orders-Mini" + extractDate(deliveryDate) + ".pdf"}>
          {({ url, loading, error }) => {
            console.log(url, error);
            return loading ? 'Loading document...' : 'Download now!'
          }
          }
        </PDFDownloadLink>
      </div>
      <PDFViewer style={viewerStyle}>
        <MiniDocument ol={orderList}/>
      </PDFViewer>
    </>
  );
}

const downloadStyle = {
  display: "block",
  margin: "45 auto",
  width: "100%",
};


const viewerStyle = {
  display: "block",
  margin: "45 auto",
  width: "100%",
  height: "92vh",
};
