import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Badge from "@mui/material/Badge";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import { TraceOrderConsumer } from "../contexts/trace-order";
import { css } from "@emotion/react";
import CircleLoader from "react-spinners/CircleLoader";
import Divider from '@mui/material/Divider';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toISTFormatString } from "../utils/date";
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';

const override = css`
  position:fixed;
  left: 50%;
  margin-left: -4em;
  top: 50%;
  border: 2px;
`;

//Item of the order list
function Item2(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        borderRadius: 1,
        textAlign: "left",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item2.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

//item of the trace order page
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        borderRadius: 1,
        m: 1,
        p: 1,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default function TraceOrder() {
  const { traceOrders, generateTraceForOrders, orderList, updateSkipList, deliveryDate, setDeliveryDate } = TraceOrderConsumer();
  const [isGenerateProcessing, setIsGenerateProcessing] = useState(false);

  const [loading, setLoading] = React.useState(false);
  const isMounted = React.useRef(null);

  const [submitSuccessPrompt, setSubmitSuccessPrompt] = useState(false);
  const [submitFailPrompt, setSubmitFailPrompt] = useState(false);
  const [failMessage, setFailMessage] = useState("");

  const closeSuccessPrompt = () => {
    setSubmitSuccessPrompt(false);
    setLoading(true);
    traceOrders()
      .then(() => updateSkipList())
      .finally(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      });
  }

  const closeFailPrompt = () => {
    setSubmitFailPrompt(false);
    setFailMessage("");
  }

  // generate button
  const generate = () => {
    // console.log("Generate triggered " + value);
    setIsGenerateProcessing(true);
    setLoading(true);
    generateTraceForOrders()
      .then(async (response) => {
        setIsGenerateProcessing(false);
        setLoading(false);
        if (response.ok) {
          return response.json()
        } else {
          throw response
        }
      })
      .then(result => {
        console.log(result);
        setSubmitSuccessPrompt(true);
      })
      .catch(error => {
        error.json().then(err => {
          console.log(err)
          setIsGenerateProcessing(false);
          setLoading(false);
          setFailMessage(err.error)
          setSubmitFailPrompt(true);
        })
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false)
          setIsGenerateProcessing(false);
        }
      });
  };

  // trace orders on change in delivery date
  React.useEffect(() => {
    setLoading(true)
    isMounted.current = true
    traceOrders()
      .then(() => updateSkipList())
      .finally(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      });

    return () => {
      // executed when unmount
      isMounted.current = false;
    };
    // eslint-disable-next-line 
  }, [deliveryDate])

  function ActivityCard(props) {
    const { order } = props;
    const { getOrderAudit, postOrderAudit } = TraceOrderConsumer();
    const [expanded, setExpanded] = useState(false);
    const [localRefresh, setLocalRefresh] = useState(false);
    const nMap = new Map();
    // nMap.set("auction_2021-11-01T09:00:22+05:30", true);
    // nMap.set("catch_2021-11-07T08:30:22+05:30", true);
    // nMap.set("dispatch_2021-11-02T12:30:22+05:30", true);
    const [auditList, setAuditList] = useState(nMap);

    const skipActivity = (activity) => {
      let skipActivities = auditList;
      skipActivities.set(activity, true);
      console.log(skipActivities);
      setAuditList(new Map(skipActivities));
    }

    const unskipActivity = (activity) => {
      let skipActivities = auditList;
      skipActivities.set(activity, false);
      console.log(skipActivities);
      setAuditList(new Map(skipActivities));
    }

    const refreshAudit = async () => {
      setLocalRefresh(true);
      getOrderAudit(order.order_id)
        .then(results => {
          console.log(results);
          let skipActivities = new Map();
          for (let i = 0; i < results.skipped_activities.length; i++) {
            const key = results.skipped_activities[i]["activity_id"];
            skipActivities.set(key, true);
          }
          setAuditList(skipActivities)
        })
        .catch(error => {
          setAuditList(new Map());
          error.json().then(err => {
            console.log(err)
          })
        })
        .finally(() => {
          setLocalRefresh(false);
        })
    }

    const submitAudit = async () => {
      const skipActivities = [];
      auditList.forEach((value, key) => {
        if (value) {
          skipActivities.push(key);
        }
      });
      setLocalRefresh(true);
      postOrderAudit(order.order_id, skipActivities)
        .then(results => {
          console.log(results);
          return updateSkipList();
        }).then(() => {
          setLocalRefresh(false);
        })
        .catch(err => {
          console.log(err);
        });

    }

    const selectAll = () => {
      let skipActivities = new Map();
      for (let i = 0; i < order.activities.length; i++) {
        const key = order.activities[i].activity_id;
        skipActivities.set(key, true);
      }
      setAuditList(skipActivities)
    }

    const deselectAll = () => {
      let skipActivities = new Map();
      for (let i = 0; i < order.activities.length; i++) {
        const key = order.activities[i].activity_id;
        skipActivities.set(key, false);
      }
      setAuditList(skipActivities)
    }

    const toggleExpand = () => {
      setExpanded(!expanded);
      if (!expanded && auditList.size === 0) {
        refreshAudit();
      }
    }

    return (
      <Card variant="outlined" sx={{ mb: 1, borderWidth: 2 }} key={order.order_id + "_a"} >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            bgcolor: "background.paper",
            justifyContent: "space-around",
          }}
        >
          <Item2 sx={{ width: "30%" }} key={order.order_id + "_1"}>
            <CardContent>
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }} gutterBottom>
                Order Id: {order.order_id}
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }} gutterBottom>
                Customer Id: {order.order_detail.customer_id}
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }} gutterBottom>
                Delivery Date: {order.delivery_date}
              </Typography>

              <Typography sx={{ fontSize: 12, fontWeight: "bold" }} gutterBottom>
                Total Line Items: {order.order_line_item.length}
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }} gutterBottom>
                Shipping Address:
              </Typography>
              <Typography sx={{ fontSize: 12, marginLeft: "20px" }} gutterBottom>
                {order.order_detail.shipping_properties.first_name} {order.order_detail.shipping_properties.last_name}
              </Typography>
              <Typography sx={{ fontSize: 12, marginLeft: "20px" }} gutterBottom>
                {order.order_detail.shipping_properties.address_1}
              </Typography>
              <Typography sx={{ fontSize: 12, marginLeft: "20px" }} gutterBottom>
                {order.order_detail.shipping_properties.address_2}
              </Typography>
              <Typography sx={{ fontSize: 12, marginLeft: "20px" }} gutterBottom>
                {order.order_detail.shipping_properties.city},{order.order_detail.shipping_properties.state},{order.order_detail.shipping_properties.postcode}
              </Typography>
            </CardContent>
          </Item2>

          <Item2 sx={{ width: "60%" }} >
            <CardContent>
              {order.order_line_item.map((line_item) => (
                <div key={order.order_id + "_1_" + line_item.line_item_id} style={{ marginLeft: "20px" }}>
                  <Typography sx={{ fontSize: 12 }} gutterBottom>
                    Line Item: {line_item.line_item_id}
                  </Typography>
                  <Typography sx={{ fontSize: 12, marginLeft: "20px" }} gutterBottom>
                    SKU: {line_item.sku}
                  </Typography>
                  <Typography sx={{ fontSize: 12, marginLeft: "20px" }} gutterBottom>
                    No of boxes: {line_item.quantity}
                  </Typography>
                  <Typography sx={{ fontSize: 12, marginLeft: "20px" }} gutterBottom>
                    Price per box: Rs. {line_item.price}/-
                  </Typography>
                  <Divider variant="middle" />
                </div>
              ))}
            </CardContent>

          </Item2>

          <Item2 sx={{ mt: 1.5, mb: 1, width: "10%" }} key={order.order_id + "_2"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                height: "100%",
                mr: 1.5,
              }}
            >
              <Item2>
                <Stack onClick={toggleExpand} style={{ cursor: "pointer" }} >
                  <Badge
                    color="primary"
                    badgeContent={(order.activities ? order.activities.length : 0)}
                    max={99}
                    sx={{ zIndex: 10 }}
                  >
                    <AssignmentIcon />
                  </Badge>
                </Stack>
              </Item2>
            </Box>
          </Item2>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              bgcolor: "#eaeaea",
              justifyContent: "flex-start",
              p: 1
            }}

          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                bgcolor: "#eaeaea",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" sx={{ marginRight: 3 }}>Audit Activities</Typography>
              <CircleLoader color={"#4A90E2"} loading={localRefresh} css={override} size={50} margin={5} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                bgcolor: "#eaeaea",
                justifyContent: "flex-start",
              }}
            >
              <Button sx={{ m: 1 }} variant="contained" onClick={selectAll} > Select All</Button>
              <Button sx={{ m: 1 }} variant="contained" onClick={deselectAll} > Deselect All</Button>
              <Button sx={{ m: 1 }} variant="contained" onClick={refreshAudit} > Refresh</Button>
              <Button sx={{ m: 1 }} variant="contained" onClick={submitAudit} > Submit Audit</Button>
            </Box>
            {order.activities && order.activities.map((activity) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: 3
                }}
                key={activity.activity_id + "_abc"}
              >
                <FormControlLabel
                  label="skip"
                  control={
                    <Checkbox
                      label="skip"
                      checked={auditList.has(activity.activity_id) ? auditList.get(activity.activity_id) : false}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        if (event.target.checked) {
                          skipActivity(activity.activity_id);
                        } else {
                          unskipActivity(activity.activity_id);
                        }
                      }}
                    />}
                  labelPlacement="start"
                  sx={{
                    marginRight: 5
                  }}

                />
                {activity.thumbnail_presigned_url != "" &&
                  <img
                    src={activity.thumbnail_presigned_url}
                    style={{ width: "100px", height: "100px", border: "none" }}
                    onError={(event) => event.target.style.display = 'none'}
                  />
                }

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    textAlign: "left",
                    margin: 1
                  }}
                >
                  <Typography variant="body1" gutterBottom>
                    {activity.activity_type}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {activity.location_name}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {toISTFormatString(activity.date_time)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {activity.comments}
                  </Typography>
                </Box>
              </Box>

            ))}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                bgcolor: "#eaeaea",
                justifyContent: "flex-start",
              }}
            >
              <Button sx={{ m: 1 }} variant="contained" onClick={selectAll} > Select All</Button>
              <Button sx={{ m: 1 }} variant="contained" onClick={deselectAll} > Deselect All</Button>
              <Button sx={{ m: 1 }} variant="contained" onClick={refreshAudit} > Refresh</Button>
              <Button sx={{ m: 1 }} variant="contained" onClick={submitAudit} > Submit Audit</Button>
            </Box>
          </Box>
        </Collapse>
      </Card>
    );
  }

  //print button
  const navigate = useNavigate();

  const pdfView = () => {
    navigate("/pdfview");
  };

  const miniPdfView = () => {
    navigate("/minipdfview");
  };

  return (
    <>
      <Paper elevation={3} sx={{ top: "10px", p: 1 }}>
        <Box
          sx={{
            m: 1,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "baseline"
          }}
        >
          <Item>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
              <DatePicker
                label="Delivery Date"
                value={deliveryDate}
                onChange={() => {

                }}
                onAccept={(newValue) => {
                  console.log(newValue.toISOString());
                  setDeliveryDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Item>
          <Item>
            <Button
              variant="contained"
              color="success"
              onClick={generate}
              disabled={isGenerateProcessing}
            >
              Generate
            </Button>
          </Item>
          <Item>
            <Button
              variant="contained"
              color="success"
              onClick={pdfView}
            >
              Print
            </Button>
          </Item>
          <Item>
            <Button
              variant="contained"
              color="success"
              onClick={miniPdfView}
            >
              Print Mini
            </Button>
          </Item>
        </Box>
        <CircleLoader color={"#4A90E2"} loading={loading} css={override} size={100} margin={5} />
        <Box sx={{ minWidth: 275, width: "100%" }} full width>
          {orderList &&
            <>
              <div style={{ margin: "10px" }}>
                {orderList.length == 1 && <>{orderList.length} order</>}
                {orderList.length > 1 && <>{orderList.length} orders</>}
                {orderList.length == 0 && <>No order</>} found!
              </div>
              {orderList && orderList.map((item) => (
                <ActivityCard order={item} key={item.order_id + "_11"} />
              ))}
              {/* {card} */}
            </>
          }
        </Box>
        <div>
          <Dialog
            open={submitSuccessPrompt}
            onClose={closeSuccessPrompt}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Trace Orders - Generation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Trace Orders generation succeeded!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeSuccessPrompt}>Ok</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={submitFailPrompt}
            onClose={closeFailPrompt}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Trace Orders - Generation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {failMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeFailPrompt}>Ok</Button>
            </DialogActions>
          </Dialog>

        </div>
      </Paper>
    </>
  );
}
