import React, { useEffect, useState } from "react";
import ActivityForm from "./components/ActivityForm";
import ActivityPage from "./components/ActivityPage";
import PublicOrderUI from "./components/PublicOrderUI";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";
import TracingOrder from "./components/TracingOrder";
import PdfModal from "./components/PdfViewer";
import MiniPdfModal from "./components/MiniPdfViewer";
import NavBar from "./components/NavBar";
import Login from "./components/Login";
import { ActivityProvider } from "./contexts/activity";
import { TraceOrderProvider  } from './contexts/trace-order';
import { PlanReportsProvider } from './contexts/plan-reports';
import AuthConsumer from "./contexts/auth";
import "./App.css";
import "./styles.css";
import { SourcingProvider } from "./contexts/sourcing";
import PlanReports from "./components/PlanReports";
import AdhocOrders from "./components/AdhocOrders";

function RequireAuth({ children }) {
  const { user } = AuthConsumer();
  const location = useLocation();
  return user !== null
    ? children
    : <Navigate
      to="/"
      replace
      state={{ path: location.pathname }}
    />;
}

function App() {

  // if (process.env.NODE_ENV === 'development') {
  //   const { worker } = require('./mocks/browser')
  //   worker.start()
  // }
  console.log(`App BUILD_ENV: ${process.env.REACT_APP_BUILD_ENV}`)


  const { user } = AuthConsumer();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (user !== null && location.pathname === "/") {
      navigate("/activities")
    }
  })


  return (
    <div className="App">
      <NavBar> </NavBar>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/orders" element={
          <PublicOrderUI/>
        }/>
        <Route path="/activities" element={
          <RequireAuth>
            <ActivityProvider>
              <ActivityPage />
            </ActivityProvider>
          </RequireAuth>


        } />
        <Route path="/activity-form" element={
          <RequireAuth>
            <ActivityProvider>
              <ActivityForm />
            </ActivityProvider>
          </RequireAuth>
        } />

        <Route path="/trace-orders" element={
          <RequireAuth>
            <TraceOrderProvider>
              <TracingOrder />
            </TraceOrderProvider>
          </RequireAuth>
        }/>
        <Route path="/plan-reports" element={
          <RequireAuth>
            <PlanReportsProvider>
              <PlanReports />
            </PlanReportsProvider>
          </RequireAuth>
        }/>
        <Route path="/pdfview" element={
          <RequireAuth>
            <TraceOrderProvider>
              <PdfModal />
            </TraceOrderProvider>
          </RequireAuth>
        }/>
        <Route path="/minipdfview" element={
          <RequireAuth>
            <TraceOrderProvider>
              <MiniPdfModal />
            </TraceOrderProvider>
          </RequireAuth>
        }/>
        
        
         <Route path="/adhoc-orders" element={
          <RequireAuth>
            <AdhocOrders />
          </RequireAuth>
        }/>

      
      </Routes>
    </div>
  )
}

export default App;
